import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';

import _ from 'lodash';
import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';
import Check from '../images/_check.svg';
import { unauthAuditProgress } from '../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../utils/amplitude';

class OurServices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  services = () => [
    {
      title: (
        <FormattedMessage
          id="main.oneOnOne"
          defaultMessage="One-on-one mental health counselling"
        />
      ),
      points: [
        <FormattedMessage
          id="main.expertOneonOne"
          defaultMessage="Expert one-on-one counselling with scientific quality monitoring"
        />,
        <FormattedMessage
          id="main.highSatisfaction"
          defaultMessage="High satisfaction and symptom reduction scores"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.flexibleScheduling"
          defaultMessage="Flexible Scheduling"
        />
      ),
      points: [
        <FormattedMessage
          id="main.sessionsThatRange"
          defaultMessage="Sessions that range anywhere from 30 to 90 minutes"
        />,
        <FormattedMessage
          id="main.eveningsWeekends"
          defaultMessage="Evenings, weekends and on-demand sessions available"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.personalizedCare"
          defaultMessage="Personalized Care"
        />
      ),
      points: [
        <FormattedMessage
          id="main.matchWithAPractitioner"
          defaultMessage="Match with a practitioner based on your reasons for seeking counselling, preferred language, and religion"
        />,
        <FormattedMessage
          id="main.matchResultsAreRanked"
          defaultMessage="Match results are ranked based on the practitioner's effectiveness score for your profile"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.fullRangeServices"
          defaultMessage="Full range of services"
        />
      ),
      points: [
        <FormattedMessage
          id="main.oneOnOneFamily"
          defaultMessage="One-on-one, group, group and couples counselling available."
        />,
        <FormattedMessage
          id="main.weHelpWithTheFullRange"
          defaultMessage="We help with the full range of mental health difficulties, addictions and stressors"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.lifeCoaching"
          defaultMessage="Life Coaching"
        />
      ),
      points: [
        <FormattedMessage
          id="main.certifiedCanadian"
          defaultMessage="Certified life coaches."
        />,
        <FormattedMessage
          id="main.focusedOnGoalSetting"
          defaultMessage="Focused on goal setting, lifestyle changes & increased productivity"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.affordability"
          defaultMessage="Affordability"
        />
      ),
      points: [
        <FormattedMessage
          id="main.sessionsCost"
          defaultMessage="Sessions cost just just $37.50 per 30 minutes."
        />,
        <FormattedMessage
          id="main.halfTheNationalAverage"
          defaultMessage="Half the cost of the national average for traditional therapy"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.accessibility"
          defaultMessage="Accessibility"
        />
      ),
      points: [
        <FormattedMessage
          id="main.chatWithYourCounsellor"
          defaultMessage="Chat with your counsellor in the comfort of your own home, a quiet space in the office, or anywhere you can use your device"
        />,
        <FormattedMessage
          id="main.ifYourSituation"
          defaultMessage="If your situation makes it difficult to travel, you don’t need to worry about missing your session"
        />,
      ],
    },
    {
      title: (
        <FormattedMessage
          id="main.shorterWaitTimes"
          defaultMessage="Significantly shorter wait times"
        />
      ),
      points: [
        <FormattedMessage
          id="main.guaranteedWaitTimes"
          defaultMessage="Guaranteed wait times of less than 24 hours after you match with your counsellor"
        />,
      ],
    },
  ];

  toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: 'our_services' });
    unauthAuditProgress('our_services', 'open_signup');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  renderServiceList = services =>
    _.map(services, service => (
      <Fade delay={100} duration={1500}>
        <div key={services.indexOf(service)} className="services-container">
          <img className="check" src={Check} alt="check" />
          <div>
            <h1>{service.title}</h1>
            <ul>
              {_.map(service.points, point => (
                <li key={service.points.indexOf(point)}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      </Fade>
    ));

  render() {
    const services = this.services();
    const leftServices = services.slice(0, 4);
    const rightServices = services.slice(4);
    return (
      <Layout location={this.props.location}>
        <div className="our-services">
          <SectionContainer num={1}>
            <h1 className="title">
              <FormattedMessage
                id="main.comprehensiveServices"
                defaultMessage="Inkblot’s comprehensive services can help address your individual needs."
              />
            </h1>
            <div className="row">
              <div className="col-6">
                {this.renderServiceList(leftServices)}
              </div>
              <div className="col-6">
                {this.renderServiceList(rightServices)}
              </div>
            </div>
            <div style={{ textAlign: 'center', paddingBottom: '150px' }}>
              <h2 className="title">
                <FormattedMessage
                  id="main.firstStep"
                  defaultMessage="Take your first step towards feeling better."
                />
              </h2>
              <a
                role="presentation"
                style={{ margin: '0 auto' }}
                className="pink-gradient-button"
                title="Create An Account"
                onClick={() => this.toggleSignUp()}
              >
                <FormattedMessage
                  id="main.createAnAccount"
                  defaultMessage="Create an account"
                />
              </a>
            </div>
          </SectionContainer>
        </div>
      </Layout>
    );
  }
}

OurServices.propTypes = {
  location: PropTypes.object.isRequired,
};

export default OurServices;
